import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import { TextInput, CellPhoneInput } from '../commons';
import { SnackBar, SpinnerV } from '../widgets';
import { PrivacyPolicyDialog } from '../terms';
import {
	FormControlLabel,
	Checkbox,
	IconButton,
	Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TagManager from 'react-gtm-module';
import formatPhonePH from 'components/utils/formatPhonePH';

const DEFAULT_CODE_TLF = process.env.REACT_APP_DEFAULT_CODE_TLF;

function SharedForm(props) {
	const router = useRouter();
	const [first_name, setFirstName] = useState('');
	const [last_name, setLasttName] = useState('');
	const [email, setEmail] = useState('');
	const [phone_one, setPhoneOne] = useState('');
	const [formErrors, setFormErrors] = useState([]);
	const [dialog, setDialog] = useState(false);
	const [shared, setShared] = useState(false);
	const [sharedEmail, setSharedEmail] = useState(['']);
	const [sharedErrors, setSharedErrors] = useState([]);
	const [assisted_selling, setAssistedSelling] = useState(false);

	const [openAlert, setOpenAlert] = useState(false);
	const [messageAlert, setMessageAlert] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const { titular, actions, isUser } = props;
		if (!isUser) {
			setFirstName(titular.first_name || '');
			setLasttName(titular.last_name || '');
			setEmail(titular.email || '');
			setPhoneOne(titular.phone_one || '');
			actions.handleStateAffiliation('titular', titular);
		}
	}, []);

	const handleInput = (event, telNumber) => {
		if (event === 'phone_one') {
			setPhoneOne(formatPhonePH(telNumber));
			validateField('phone_one', telNumber);
		} else {
			const { name, value } = event.target;
			switch (name) {
				case 'first_name':
					setFirstName(value);
					break;
				case 'last_name':
					setLasttName(value);
					break;
				case 'email':
					setEmail(value);
					break;
				default:
					break;
			}
			validateField(name, value);
		}
	};

	const validateField = (field, value) => {
		let validate = false;
		switch (field) {
			case 'first_name':
				validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
				!validate
					? (formErrors.first_name = 'Enter a valid name.')
					: delete formErrors.first_name;
				break;
			case 'last_name':
				validate = /^([a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,.-]{3,})$/.test(value);
				!validate
					? (formErrors.last_name = 'Enter a valid last name.')
					: delete formErrors.last_name;
				break;
			case 'email':
				validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
				!validate
					? (formErrors.email = 'Enter a valid email address.')
					: delete formErrors.email;
				break;
			case 'phone_one':
				validate = /^([0-9()+ -]{10,})$/.test(value);
				!validate && value
					? (formErrors.phone_one = 'Enter a valid phone number.')
					: delete formErrors.phone_one;
				break;
			default:
				break;
		}
		setFormErrors({ ...formErrors });
	};

	const handleSubmit = async (event) => {
		const isMobile = router?.asPath?.includes('/m');
		setLoading(true);
		event.preventDefault();

		let quotationForSend = props.quotationForSend;

		const percent_off =
			quotationForSend?.percent_off !== 'default'
				? quotationForSend.percent_off
				: 0;

		let quotation = {};
		quotation = {
			first_name,
			last_name,
			email,
			phone_one,
			percent_off,
			assisted_selling,
			beneficiaries: [...props.quotation],
			country: props.quotation[0].country,
			link_rrss_discount: props.share_link_discount || '',
			...(shared && {
				share_with: sharedEmail
			})
		};
		if (props.isLanding) quotation.is_ms = true;
		quotationForSend.parients = [...props.quotation];
		props.actions.saveQuotationClient({
			first_name,
			last_name,
			email,
			phone_one
		});
		props.actions.saveQuotation(quotationForSend);
		const response = await props.actions.sharedQuotation({
			...quotation,
			isoCode: props.isoCode
		});
		if (response) {
			setOpenAlert(response.openAlert);
			setMessageAlert(response.messageAlert);
			setLoading(false);
			const pathname = '/quotation/sendQuotation';
			const asPath = `${isMobile ? '/m' : ''}/quote-sent`;

			if (response.success === true) {
				const tagManagerArgs = {
					dataLayer: { event: 'evento-cotizacion-enviada-paso-seis' }
				};
				TagManager.dataLayer(tagManagerArgs);
				if (props.handleClose) props.handleClose();
				setTimeout(() => {
					if (props.isUser) {
						if (props.isAdmin) {
							router
								.push(
									{
										pathname,
										query: { isUser: props.isUser, isAdmin: true }
									},
									asPath
								)
								.then(() => window.scrollTo(0, 0));
						} else {
							router
								.push(
									{
										pathname,
										query: { isUser: props.isUser }
									},
									asPath
								)
								.then(() => window.scrollTo(0, 0));
						}
					} else if (props.isLanding) {
						router
							.push(
								{
									pathname,
									query: { isLanding: true }
								},
								asPath
							)
							.then(() => window.scrollTo(0, 0));
					} else {
						router.push(pathname, asPath).then(() => window.scrollTo(0, 0));
					}
				}, 3000);
			}
		}
	};

	const handleClose = () => setOpenAlert(false);

	const closeDialog = (e, reason) => {
		if (reason === 'backdropClick') {
			return false;
		}

		if (reason === 'escapeKeyDown') {
			return false;
		}

		if (!reason) {
			setDialog(false);
		}
	};

	const toggleShared = (value) => {
		if (!value) {
			setSharedEmail(['']);
			setSharedErrors([]);
		}
		setShared(value);
	};

	const toggleAsissted = (value) => {
		setAssistedSelling(value);
	};

	const handleInputShared = (event, index) => {
		const { value } = event.target;
		let validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
		const tmp = sharedEmail;
		tmp[index] = value;
		setSharedEmail([...tmp]);

		const errors = sharedErrors;
		if (!validate) errors[index] = 'Enter a valid email address.';
		else errors.splice(index, 1);
		setSharedErrors([...errors]);
	};

	const removeShared = (idx) => {
		const tmp = sharedEmail;
		const errors = sharedErrors;
		tmp.splice(idx, 1);
		setSharedEmail([...tmp]);
		errors.splice(idx, 1);
		setSharedErrors([...errors]);
	};

	const handlePhone = (telNumber) => handleInput('phone_one', telNumber);
	return (
		<form
			autoComplete="false"
			className="flex justify-center items-center flex-wrap w-full"
			onSubmit={(e) => handleSubmit(e)}
		>
			{loading && <SpinnerV />}
			<TextInput
				name="first_name"
				label="First name"
				placeholder="Enter first name"
				value={first_name}
				helperText={formErrors.first_name}
				errors={formErrors.first_name}
				addClass="wInputTwo"
				onChange={(e) => handleInput(e)}
				required={true}
			/>
			<TextInput
				name="last_name"
				label="Last name"
				placeholder="Enter last name"
				value={last_name}
				helperText={formErrors.last_name}
				errors={formErrors.last_name}
				addClass="wInputTwo"
				onChange={(e) => handleInput(e)}
				required={true}
			/>
			<TextInput
				name="email"
				type="email"
				label="Email Addres"
				placeholder="Enter email address"
				value={email}
				helperText={formErrors.email}
				errors={formErrors.email}
				addClass="wInputTwo"
				onChange={(e) => handleInput(e)}
				required={true}
			/>
			<CellPhoneInput
				label="Mobile number"
				addClass="wInputTwo"
				value={phone_one}
				onChange={handlePhone}
				error={formErrors?.phone_one}
				required={true}
			/>
			<div className="w-full px-2 py-3">
				{(props.isAllyMaster || props.isAllyMatrix) && (
					<div>
						<FormControlLabel
							className="w-auto"
							label={
								<span className="text-sm w-full text-gray-800">
									I would like a Big Broder to help with membership.
									<Tooltip title="I would like a Big Broder to help with membership.">
										<span className="font-bold text-purple-500"> (?)</span>
									</Tooltip>
								</span>
							}
							control={
								<Checkbox
									name="assisted_selling"
									onClick={(e) => toggleAsissted(!assisted_selling)}
									checked={assisted_selling || false}
								/>
							}
						/>
						{assisted_selling && (
							<div className="bg-purple-200 p-3 rounded mb-5">
								<p>
									With this option one of our commercial advisors will contact
									the policyholder to explain the product in detail, answer
									their questions and encourage affiliation. We take care of the
									affiliation and you receive the payment per referral!
								</p>
							</div>
						)}
					</div>
				)}

				<div className="w-full flex flex-wrap justify-between items-center">
					<div>
						<FormControlLabel
							label={
								<span className="text-sm w-full text-gray-800">
									Would you like to share this quote with someone else?
								</span>
							}
							control={
								<Checkbox
									checked={shared || false}
									onClick={() => toggleShared(!shared)}
									name="shared"
									value={shared}
								/>
							}
						/>
					</div>

					<div className="w-full md:w-auto text-center md:text-right">
						{shared && (
							<div
								className="font-bold text-pink-500 text-sm my-3 md:my-0 cursor-pointer"
								onClick={() => setSharedEmail(sharedEmail.concat(['']))}
							>
								+ Add email address
							</div>
						)}
					</div>
				</div>

				{shared && (
					<div className="w-full flex flex-wrap">
						{sharedEmail.map((se, idx) => (
							<div className="flex items-center wInputTwo" key={idx}>
								<TextInput
									name="shared_email"
									type="email"
									label="Email address"
									placeholder="Email address"
									value={se}
									helperText={sharedErrors[idx]}
									errors={sharedErrors[idx]}
									addClass="wInputFull h-20"
									onChange={(e) => handleInputShared(e, idx)}
									required={false}
								/>
								{sharedEmail.length > 1 && (
									<IconButton
										aria-label="delete"
										style={{ marginTop: -10 }}
										onClick={() => removeShared(idx)}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								)}
							</div>
						))}
					</div>
				)}
			</div>

			<button
				id="enviar-cotización-paso5"
				type="submit"
				disabled={
					Object.keys(formErrors).length > 0 ||
					!first_name ||
					!last_name ||
					!email ||
					!phone_one ||
					(shared &&
						(sharedErrors.filter((e) => e).length > 0 ||
							sharedEmail.some((s) => !s)))
				}
				className="primary-button my-8"
			>
				Send quote
			</button>
			<PrivacyPolicyDialog open={dialog} handleClose={closeDialog} />
			<SnackBar
				openAlert={openAlert}
				messageAlert={messageAlert}
				handleClose={handleClose}
			/>
		</form>
	);
}

const mapStateToProps = (state, props) => {
	const quotation = state.affiliation.get('quotation');
	const quotationForSend = state.customers.get('quotation');
	const share_link_discount = state.ally.get('share_link_discount');
	const titular = state.affiliation.get('titular');
	const isoCode = state.currencies.get('isoCode');
	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;

	return {
		quotation,
		quotationForSend,
		share_link_discount,
		titular,
		isoCode,
		isAlly,
		isAllyMaster,
		isAllyMatrix
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedForm);
